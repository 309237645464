<template>
  <div class="border-right bg-light overflow-auto sidebar" v-if="user.has_set_password">
    <sticky-header title="Toast Lettings">
      <template #title><img src="../assets/logo-header.svg" alt="Toast Lettings" height="36" /></template>
      <template #buttons>
        <b-dropdown class="justify-self-right" right variant="light" no-caret>
          <template #button-content><b-icon icon="three-dots-vertical" /></template>

          <b-dd-header>Signed in as...</b-dd-header>
          <b-dd-text class="px-2">
            <p class="font-weight-bold mb-0">{{ user && user.name }}</p>
            <p class="mb-0 text-muted"><small>{{ user && user.email }}</small></p>
          </b-dd-text>
          <b-dd-divider />
          <b-dd-item :to="{ name: 'settings.reset-password' }">Change Password</b-dd-item>
          <b-dd-item :to="{ name: 'auth.logout' }">Logout</b-dd-item>
        </b-dropdown>
      </template>
    </sticky-header>
    <b-nav class="py-2" vertical>
        <b-nav-item :to="{name: 'dashboard'}" exact-active-class="active"><fa-icon icon="columns"/> Dashboard</b-nav-item>
        <b-nav-item :to="{name: 'reports'}" active-class="active" v-if="can('Super Admin')"><fa-icon icon="chart-line"/> Reporting</b-nav-item>
        <b-nav-item :to="{name: 'system'}" active-class="active" v-if="can('Super Admin')"><fa-icon icon="cog"/> System</b-nav-item>
      <b-nav-text v-if="can('Maintenances/View')">Maintenance</b-nav-text>
        <b-nav-item :to="{name: 'maintenance'}" exact-active-class="active" v-if="can('Maintenances/View')"><fa-icon icon="clipboard"/> Jobs</b-nav-item>
        <b-nav-item :to="{name: 'maintenance.visits'}" active-class="active" v-if="can('Maintenances/View')"><fa-icon icon="calendar"/> Calendar</b-nav-item>
        <b-nav-item :to="{name: 'maintenance.recurring'}" active-class="active" v-if="can('Maintenances/Create')"><fa-icon icon="redo"/> Recurring Jobs</b-nav-item>
        <b-nav-item :to="{name: 'maintenance.contractors'}" active-class="active" v-if="can('Maintenances/Create')"><fa-icon icon="users"/> Contractors</b-nav-item>
        <b-nav-item :to="{name: 'maintenance.complete'}" active-class="active" v-if="can('Maintenances/Create')"><fa-icon icon="file-contract"/> Complete Jobs</b-nav-item>
      <b-nav-text v-if="can('Properties/View')">Properties</b-nav-text>
        <b-nav-item :to="{name: 'properties'}" active-class="active" v-if="can('Properties/View')"><fa-icon icon="hotel"/> Properties</b-nav-item>
        <b-nav-item :to="{name: 'property-keys'}" active-class="active" v-if="can('Properties/View')"><fa-icon icon="key"/> Keys</b-nav-item>
      <b-nav-text v-if="can('Guests/View') || can('Bookings/View')">Lettings</b-nav-text>
        <b-nav-item :to="{name: 'guests'}" active-class="active" v-if="can('Guests/View')"><fa-icon icon="address-card"/> Guests</b-nav-item>
        <b-nav-item :to="{name: 'bookings'}" active-class="active" v-if="can('Bookings/View')"><fa-icon icon="key"/> Bookings</b-nav-item>
        <b-nav-item :to="{name: 'booking-confirmations'}" active-class="active" v-if="can('Bookings/View')"><fa-icon icon="list"/> Review Bookings</b-nav-item>
      <b-nav-text v-if="can('Customers/View') || can('Jobs/View') || can('Rotas/View') || can('Team management/View') || can('Stock management/View') || can('Checklist Templates/View') || can('Rotas/Edit')">Cleaning</b-nav-text>
        <b-nav-item :to="{name: 'customers'}" active-class="active" v-if="can('Customers/View')"><fa-icon icon="address-card"/> Customers</b-nav-item>
        <b-nav-item :to="{name: 'jobs'}" active-class="active" v-if="can('Jobs/View')"><fa-icon icon="hand-sparkles"/> Jobs</b-nav-item>
        <b-nav-item :to="{name: 'job-confirmations'}" active-class="active" v-if="can('Jobs/Edit')"><fa-icon icon="list"/> Review Jobs</b-nav-item>
        <b-nav-item :to="{name: 'jobs-report'}" active-class="active" v-if="can('Jobs/Edit')"><fa-icon icon="file-invoice"/> Jobs Report</b-nav-item>
        <b-nav-item :to="{name: 'rotas'}" active-class="active" v-if="can('Rotas/View')"><fa-icon icon="calendar"/> Rotas</b-nav-item>
        <b-nav-item :to="{name: 'teams'}" active-class="active" v-if="can('Team management/View')"><fa-icon icon="users"/> Team Management</b-nav-item>
        <b-nav-item :to="{name: 'stock'}" active-class="active" v-if="can('Stock management/View')"><fa-icon icon="pump-soap"/> Stock Management</b-nav-item>
        <b-nav-item :to="{name: 'checklist-templates'}" active-class="active" v-if="can('Checklist Templates/View')"><fa-icon icon="list"/> Checklist Templates</b-nav-item>
        <b-nav-item :to="{name: 'no-entries'}" active-class="active" v-if="can('Rotas/Edit')"><fa-icon icon="ban"/> No Entries</b-nav-item>
      <b-nav-text v-if="can('Absences/View') || can('Timesheets/View') || can('Users/View')">Business Management</b-nav-text>
        <b-nav-item :to="{name: 'absences'}" active-class="active" v-if="can('Absences/View')"><fa-icon icon="umbrella-beach"/> Absences</b-nav-item>
        <b-nav-item :to="{name: 'attendances'}" active-class="active" v-if="can('Timesheets/View')"><fa-icon icon="clock"/> Clock Ins/Outs</b-nav-item>
        <b-nav-item :to="{name: 'timesheets'}" active-class="active" v-if="can('Timesheets/View')"><fa-icon icon="stopwatch"/> Timesheets</b-nav-item>
        <b-nav-item :to="{name: 'users'}" active-class="active" v-if="can('Users/View')"><fa-icon icon="users"/> Users</b-nav-item>
      <b-nav-text v-if="can('Statements/View') || can('Recurring costs/View')">Financials</b-nav-text>
        <b-nav-item :to="{name: 'statements'}" active-class="active" v-if="can('Statements/View')"><fa-icon icon="file-invoice"/> Owner Statements</b-nav-item>
        <b-nav-item :to="{name: 'invoices'}" active-class="active" v-if="can('Recurring costs/View')"><fa-icon icon="file-invoice-dollar"/> Recurring Costs / Invoices</b-nav-item>
        <b-nav-item :to="{name: 'monthly-accounts'}" active-class="active" v-if="can('Recurring costs/View')"><fa-icon icon="calendar"/> Monthly Accounts</b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import StickyHeader from './StickyHeader.vue'

export default {
  components: { StickyHeader },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    can(perm) {
      let pass = false
      this.user.permissions.forEach((permission) => {
        if (permission.name == perm) pass = true;
      });

      return pass;
    }
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .sidebar {
    min-width: 20rem;
    max-width: 20rem;
  }
}

svg:not(:root).svg-inline--fa {
  width: 1.5rem;
  margin-right: 0.8rem;
}

.navbar-text {
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 1.6rem 0.8rem 0.4rem;
  color: #6C757D;
}

.nav-item a.active {
  background: #E2E2E2;
}
</style>
