<template>
  <div class="bg-white d-flex flex-row vh-100 vw-100">
    <sidebar :class="{ 'd-block w-100': show }" class="d-none d-md-block overflow-scroll" />
    <main :class="{ 'd-none': show }" class="d-md-block overflow-scroll flex-fill">
      <router-view />
    </main>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'

export default {
  components: { Sidebar },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.$root.$on('dd::sidebar::toggle', () => {
      this.show = !this.show;
    });
  },
  watch: {
    '$route'() {
      if (this.show) {
        this.$root.$emit('dd::sidebar::toggle')
      }
    }
  }
}
</script>

<style>
@media (max-width: 767px) {
  main {
    max-width: 100vw;
  }
}
</style>
